/** @jsx jsx */
import { jsx, Heading } from "theme-ui"
import { baseColors } from "@theme-ui/preset-tailwind"
import { MdArrowDownward } from "react-icons/md"
import { Link } from "gatsby"

type AnimatedBlocksProps = {
  isDocs: boolean;
}

const AnimatedBlocks = ({ isDocs }: AnimatedBlocksProps) => {
  return (
    <div sx={{
      width: "100vw",
      position: "relative",
      left: "calc(-50vw + 50%)",
    }}>
      <div sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: isDocs
          ? "column"
          : ["column", null, null, "row", null],
        justifyContent: "center",
        m: 4,
      }}>
        <div sx={{
          borderStyle: "dashed",
          borderWidth: "3px",
          borderColor: "primary",
          p: 3,
        }}>
          <Heading as="h2" sx={{ 
            mb: 2,
            mt: 0,
            textAlign: "center"
          }}>
            SHS Services
          </Heading>
          <Link
            to="/Level0Services"
            sx={{
              textDecoration: "none",
            }}
            >
            <div
            sx={{
              minWidth: "240px",
              maxWidth: "330px",
              bg: baseColors.blue[2],
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              p: [2, 3, null, null, null],
              mb: 2,
              color: baseColors.gray[8],
            }}
          >
            <Heading as="h3" sx={{ mb: 2 }}>
              Free Introduction
            </Heading>
            <ul sx={{
              m: 0,
              textAlign: "left",
              listStyleType: "none",
              paddingInlineStart: 0,
              fontStyle: "italic",
            }}>
              <li>Value Proposition</li>
              <li>Scope work</li>
              <li>Recomendations</li>
            </ul>
          </div>
          </Link>
          <MdArrowDownward sx={{
            flexShrink: "0",
            fontSize: 4,
            width: "stretch",
            m: "auto"
          }}/>
          <Link
            to="/Level1Services"
            sx={{
              textDecoration: "none",
            }}
            >
            <div
            sx={{
              minWidth: "240px",
              maxWidth: "330px",
              bg: baseColors.blue[3],
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              p: [2, 3, null, null, null],
              mb: 2,
              color: baseColors.gray[8],
            }}
            >
            <Heading as="h3" sx={{ mb: 2 }}>
              Level I
            </Heading>
            <ul sx={{
              m: 0,
              textAlign: "left",
              listStyleType: "none",
              paddingInlineStart: 0,
              fontStyle: "italic",
            }}>
              <li>Value Proposition</li>
              <li>Identify Data</li>
              <li>Define Features</li>
            </ul>
          </div>
          </Link>
          <MdArrowDownward sx={{
            flexShrink: "0",
            fontSize: 4,
            width: "stretch",
            m: "auto"
          }}/>
          <Link
            to="/Level2Services"
            sx={{
              textDecoration: "none",
            }}
            >
            <div sx={{
            minWidth: "240px",
            maxWidth: "330px",
            // height: "170px",
            bg: baseColors.blue[4],
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            p: [2, 3, null, null, null],
            mb: 2,
            color: baseColors.gray[8],
          }}>
            <Heading as="h3" sx={{
              mb: 2
            }}>
              Level II
            </Heading>
            <ul sx={{
              m: 0,
              textAlign: "left",
              listStyleType: "none",
              paddingInlineStart: 0,
              fontStyle: "italic",
            }}>
              <li>Software Guidelines</li>
              <li>Acceptance Criteria</li>
              <li>Create RFP</li>
            </ul>
          </div>
          </Link>
          <MdArrowDownward sx={{
            flexShrink: "0",
            fontSize: 4,
            width: "stretch",
            m: "auto"
          }}/>
          <Link
            to="/Level3Services"
            sx={{
              textDecoration: "none",
            }}
            >
          <div sx={{
            minWidth: "240px",
            maxWidth: "330px",
            bg: baseColors.blue[5],
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            p: [2, 3, null, null, null],
            color: baseColors.gray[8],
          }}>
            <Heading as="h3" sx={{ mb: 2 }}>
              Level III
            </Heading>
            <ul sx={{
              m: 0,
              textAlign: "left",
              listStyleType: "none",
              paddingInlineStart: 0,
              fontStyle: "italic",
            }}>
              <li>Data Architecture</li>
              <li>Design Components</li>
              <li>Review RFP Bids</li>
            </ul>
          </div>
          </Link>
          </div>
        <MdArrowDownward sx={{
          flexShrink: "0",
          fontSize: 4,
          my: isDocs
            ? 3
            : [3, null, null, "auto", null],
          mx: isDocs
            ? "auto"
            : ["auto", null, null, 3, null],
          transform: isDocs
            ? "none"
            : ["none", null, null, "rotate(270deg)", null],
        }}/>
        <div sx={{
          borderStyle: "dashed",
          borderWidth: "3px",
          borderColor: "primary",
        }}>
          <Heading as="h2" sx={{
            mb: 2,
            mt: 0,
            textAlign: "center"
          }}>
            SHS Contracting
          </Heading>
          <div sx={{
            margin: "auto",
            display: "flex",
            flexDirection: isDocs
              ? "column"
              : ["column", null, null, "row", null],
            alignItems: isDocs
              ? "center"
              : ["center", null, null, "stretch", null],
            maxWidth: isDocs ? "330px" : ["330px", null, null, "100%", null],
          }}>
            <div sx={{
              minWidth: "240px",
              maxWidth: "330px",
              bg: baseColors.green[3],
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              p: [2, 3, null, null, null],
              m: 3,
              color: baseColors.gray[8],
              textAlign: isDocs
                ? "center"
                : ["center", null, null, "left", null, null],
            }}>
              <Heading as="h3" sx={{
                mb: 2
              }}>
                Iterative Agile Development
              </Heading>
              <ul sx={{
                m: 0,
                textAlign: "left",
                listStyleType: "none",
                paddingInlineStart: 0,
                fontStyle: "italic",
              }}>
                <li>Outsourced</li>
                <li>In House</li>
                <li>Both</li>
              </ul>
            </div>
            <MdArrowDownward sx={{
              flexShrink: "0",
              fontSize: 4,
              m: "auto",
              transform: isDocs
                ? "none"
                : ["none", null, null, "rotate(270deg)", null],
            }}/>
            <div sx={{
              minWidth: "240px",
              maxWidth: "330px",
              bg: baseColors.green[4],
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              p: [2, 3, null, null, null],
              m: 3,
              color: baseColors.gray[8],
              textAlign: isDocs
                ? "center"
                : ["center", null, null, "left", null, null],
            }}>
              <Heading as="h3" sx={{ mb: 2 }}>
                Incremental Business Value
              </Heading>
              <ul sx={{
                m: 0,
                textAlign: "left",
                listStyleType: "none",
                paddingInlineStart: 0,
                fontStyle: "italic",
              }}>
                <li>Early IoT Solution Viability</li>
                <li>Adapt To Priority Changes</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AnimatedBlocks.defaultProps = {
  isDocs: false,
}

export default AnimatedBlocks
