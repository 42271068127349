/** @jsx jsx */
import { jsx, Heading, Button } from "theme-ui"
import HomeLayout from "../components/home-layout"
import Blocks from "../components/animated-blocks"
import { TriangleVertical } from "../components/spacers"
import { SEO } from "gatsby-theme-swhwsys-core"

const HomePage = () => {
  return (
  <HomeLayout>
      <SEO
        title="Home"
        description="Itrel Monroe DBA as Software Hardware Systems (SHS) provides consulting and contract development services for IoT Solutions. Services include collecting and documenting IoT Solution requirements, validating business value propositions, architecture/code development and design reviews, and general technical guidance."
      />

      <section sx={{
        fontSize: [3, null, 4, null, null], 
        fontWeight: "500",
        align: "center",
      }}>
        <div 
          sx={{
            margin: "auto",
        }}>

          <p>
            My passion is writing software that interacts with hardware and IoT Solutions.
            I visualize and feel data flowing though systems and IoT Solutions.
          </p>

          <ul
            sx={{
              m: 0,
              textAlign: "left",
              listStyleType: "none",
              paddingInlineStart: 0,
              fontStyle: "italic",
            }}>
            <li>I understand how "Things" on your network or the internet</li>
            <ul
              sx={{
                m: 0,
                textAlign: "left",
                fontStyle: "italic",
              }}>
              <li>Receive command/control data sent from an IoT Solution</li>
              <li>Send data to an IoT Solution for analysis and presentation</li>
            </ul>
          </ul>

          <TriangleVertical />

          <Heading as="h1" 
            sx={{ 
              backgroundImage: (theme) =>
                `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.accent} 0%, ${theme.colors.highlight} 90% )`,
              px: 2,
              mr: 2,
              fontStyle: "italic",
              textAlign: "center",
            }}>
            IoT Solution data is a source of business value.
          </Heading>

          <p>
            You understand how IoT Solution data can be used for decision making and adding business value.
          </p>

          <p
            sx={{
              fontWeight: "heading",
            }}>
            But you may not understand how to create an IoT Solution for your business.
          </p>

        <TriangleVertical />

        <Heading as="h1"
          sx={{
            backgroundImage: (theme) =>
              `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.accent} 0%, ${theme.colors.highlight} 90% )`,
            px: 2,
            mr: 2,
            fontStyle: "italic",
            textAlign: "center",
          }}
        >
          I can help you create an IoT Solution for your business
        </Heading>

        <Blocks />

        <TriangleVertical />

        <Heading as="h1"
          sx={{
            backgroundImage: (theme) =>
              `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.accent} 0%, ${theme.colors.highlight} 90% )`,
            px: 2,
            mr: 2,
            fontStyle: "italic",
            textAlign: "center",
            borderRadius: "25px",
            boxShadow: "default",
            transition: "all 0.3s ease",
            ":hover, :focus, :active": {
              boxShadow: "lg",
              backgroundImage:  (theme) =>
                `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.highlight} 0%, ${theme.colors.accent} 90% )`,
            },
        }}
        >
          <Button
            as="a"
            href="http://www.linkedin.com/in/Itrel"
            sx={{
              backgroundColor: "inherit",
              color: "inherit",
            }}
          >
            Contact me to discuss IoT Solutions and Business Value
          </Button>
        </Heading>

      </div>
      </section>

  </HomeLayout>
  )
}

export default HomePage
